import { useCurrentOrPreviewMessage } from '../../../messages/useCurrentOrPreviewMessage';
import type { PageAccessData } from '../../../usePageAccess';
import type { ContextMessageFragment } from '@aurora/shared-generated/types/graphql-types';
import type { MessageViewFragment } from '../../../../types/graphql-types';

/**
 * Returns the latest revision message when in preview mode for the AppContextProvider.
 *
 * @param pageParamData the required data from the AppContextProvider
 */
export default function useAppContextProviderRevisionMessage(pageParamData: PageAccessData): {
  loading: boolean;
  data: ContextMessageFragment | MessageViewFragment | null;
} {
  const {
    contextMessageQueryResult: { data: contextMessageData, loading: contextMessageLoading }
  } = pageParamData;

  const { message: latestRevisionMessage, loading: revisionsLoading } = useCurrentOrPreviewMessage(
    contextMessageData?.message
  );

  return {
    loading: contextMessageLoading || revisionsLoading,
    data: latestRevisionMessage
  };
}
